import React, {useEffect, useMemo, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {useTheme} from "@material-ui/core";
import {QUIZ_STYLE_INPUT, QUIZ_STYLE_MULTI} from "./App";
import AspectFitImage from "./components/AspectFitImage";

const QuestionBar = ({currentQuestion, totalQuestions, onQuit}) => {
    const theme = useTheme();
    return (
        <div style={{background: theme.palette.primary.main, display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'white', padding: 8}}>
            <Button onClick={onQuit} style={{color: 'white'}}>Back</Button>
            <h3 style={{margin: 0}}>Question {currentQuestion} / {totalQuestions}</h3>
        </div>
    );
};

export const AnswerInputText = ({onAnswer, answer, setAnswer}) => {

    return (
        <div>
            <input style={{padding: 16, width: 'calc(100% - 32px)'}} value={answer} onChange={e => setAnswer(e.target.value)}/>
            <Button color="primary" style={{width: '100%', marginTop: 16}} variant="contained" onClick={onAnswer}>Check</Button>
        </div>
    );
}

export const AnswerInputMulti = ({options, onAnswer, answer, setAnswer}) => {

    const onChoose = chosenAnswer => () => {
        setAnswer(chosenAnswer);
    }

    useEffect(() => {
        if (answer !== "" && answer != null) {
            onAnswer()
        }
    }, [answer, onAnswer]);

    return (
        <div>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {options.map((e, i) => {
                    const colors = ["#e06868", "#4ac8de", '#e7cf58', '#4fc24f', '#ca60dc', '#e3ad7d'];
                    const color = colors[i];
                    const buttonStyle = {width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', margin: 8, fontSize: 16, background: color};
                    return (
                        <div style={{width: '50%', height: 120}}>
                            <Button style={buttonStyle} variant="contained" onClick={onChoose(e)}>{e}</Button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

const QuestionScreen = ({multiOptions, english, id, folder, onNext, currentQuestion, totalQuestions, onQuit, quizStyle}) => {
    const [answer, setAnswer] = useState("");
    const [hasAnswered, setHasAnswered] = useState(false);
    const [wasCorrect, setWasCorrect] = useState(false);

    const onAnswered = () => {
        const isRight = answer.toLowerCase() === english.toLowerCase();
        setWasCorrect(isRight);
        setHasAnswered(true);
    }

    useEffect(() => {
        setAnswer("");
        setHasAnswered(false);
    }, [id]);

    const feedbackColour = wasCorrect ? "#00ff00" : "#ff0000";
    const feedbackText = wasCorrect ? "Correct!" : "Incorrect!";

    const file = folder ? `${folder}/${id}` : id;

    return (
        <div>
            <QuestionBar currentQuestion={currentQuestion} totalQuestions={totalQuestions} onQuit={onQuit}/>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 16}}>
                <Paper style={{borderRadius: 16, width: '20cm', maxWidth: '90vw', padding: 16}}>
                    <AspectFitImage src={require("./arabic/" + file + ".png")}/>
                    {hasAnswered && (
                        <div style={{textAlign: 'center'}}>
                            <h1 style={{color: feedbackColour}}>{feedbackText}</h1>
                            <h1>{english}</h1>
                            <Button color="primary" variant="contained" style={{width: '100%'}}
                                    onClick={onNext}>{currentQuestion === totalQuestions ? "End of quiz" : "Next Question"}</Button>
                        </div>
                    )}
                    {!hasAnswered && quizStyle === QUIZ_STYLE_INPUT && <AnswerInputText onAnswer={onAnswered} answer={answer} setAnswer={setAnswer}/>}
                    {!hasAnswered && quizStyle === QUIZ_STYLE_MULTI && <AnswerInputMulti options={multiOptions} onAnswer={onAnswered} answer={answer} setAnswer={setAnswer}/>}
                </Paper>
            </div>
        </div>
    )
}

const Container = ({words, goHome, quizStyle}) => {

    const questions = useMemo(() => shuffle(words), [words]);
    const [currentQuestion, setCurrentQuestion] = useState(0);

    const question = questions[currentQuestion];

    const multiOptions = useMemo(() => {
        const optionsNotIncludingCorrect = questions.filter(e => e.english !== question.english).slice(0, 3).map(e => e.english);
        const optionsIncludingCorrect = [...optionsNotIncludingCorrect, question.english]
        return shuffle(optionsIncludingCorrect);
    }, [questions, question])

    const onNext = () => {
        if (currentQuestion === words.length - 1) {
            goHome()
            return
        }
        setCurrentQuestion(e => e + 1);
    }

    return <QuestionScreen english={question.english} id={question.id} folder={question.folder} onNext={onNext} currentQuestion={currentQuestion + 1} totalQuestions={words.length}
                           onQuit={goHome} multiOptions={multiOptions} quizStyle={quizStyle}/>
};

const shuffle = (array) => {
    const shuffled = [...array];
    let counter = shuffled.length;

    // While there are elements in the array
    while (counter > 0) {
        // Pick a random index
        let index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        let temp = shuffled[counter];
        shuffled[counter] = shuffled[index];
        shuffled[index] = temp;
    }

    return shuffled;
};

export default Container;