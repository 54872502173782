import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {QUIZ_STYLE_INPUT, QUIZ_STYLE_MULTI} from "./App";
import ReviseScreen from "./ReviseScreen";
import TestScreen from "./TestScreen";

const STATE_MENU = 0;
const STATE_PLAYING = 1;
const STATE_REVISE = 2;

function SinglePlayerApp() {

    const [currentState, setCurrentState] = useState(STATE_MENU);
    const [currentLesson, setCurrentLesson] = useState();
    const [quizStyle, setQuizStyle] = useState(QUIZ_STYLE_MULTI);

    const lessons = require("./arabic.json");

    const goHome = () => setCurrentState(STATE_MENU);

    const beginLesson = lesson => {
        setCurrentLesson(lesson);
        setCurrentState(STATE_PLAYING);
    };

    const reviseLesson = lesson => {
        setCurrentLesson(lesson);
        setCurrentState(STATE_REVISE);
    };

    const beginAll = () => {
        const words = lessons.flatMap(l => l.words);
        setCurrentLesson({words});
        setCurrentState(STATE_PLAYING);
    };

    return (
        <>
            {currentState === STATE_MENU &&
            <MenuScreen lessons={lessons} revise={reviseLesson} beginLesson={beginLesson} beginAll={beginAll} quizStyle={quizStyle} setQuizStyle={setQuizStyle}/>}
            {currentState === STATE_PLAYING && <TestScreen words={currentLesson.words} goHome={goHome} quizStyle={quizStyle}/>}
            {currentState === STATE_REVISE && <ReviseScreen words={currentLesson.words} goHome={goHome}/>}
        </>
    );
}

const MenuScreen = ({lessons, beginLesson, revise, beginAll, quizStyle, setQuizStyle}) => {
    return (
        <div style={{margin: 16}}>
            <h1>Arabic Quiz</h1>
            <h3>Select quiz style</h3>
            <select style={{width: "100%", fontSize: 16}} value={quizStyle} onChange={e => setQuizStyle(e.target.value)}>
                <option value={QUIZ_STYLE_INPUT}>Text Entry</option>
                <option value={QUIZ_STYLE_MULTI}>Multi Choice</option>
            </select>
            <div style={{marginTop: 16}}>
                {lessons.map(l => (
                    <div style={{display: "flex", width: "100%", borderBottom: "1px solid #ccc", padding: "4px 0", alignItems: "center"}}>
                        <div style={{flex: 1}}>
                            <p><b>Lesson {l.name}</b></p>
                            <p>{l.description}</p>
                            <p>{l.words.length} words</p>
                        </div>
                        <div style={{padding: "4px 0"}}>
                            <Button color="primary" variant="contained" onClick={() => beginLesson(l)} style={{width: 80}}>Quiz</Button>
                            <br/>
                            <Button color="primary" variant="contained" onClick={() => revise(l)} style={{marginTop: 16}}>Revise</Button>
                        </div>
                    </div>
                ))}
            </div>
            <Button color="primary" variant="contained" onClick={beginAll} style={{marginTop: 16}}>Quiz on all lessons</Button>
            <br/><br/><br/><br/>
        </div>
    );
};

export default SinglePlayerApp;