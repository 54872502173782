import React from "react";

const AspectFitImage = ({src, height, position}) => (
    <div style={{
        width: '100%',
        backgroundImage: `url(${src})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: position ?? 'center',
        height: height ?? 'calc(50vh - 200px)'
    }}/>
);

export default AspectFitImage;