import {useTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import React from "react";
import AspectFitImage from "./components/AspectFitImage";

const QuestionBar = ({onQuit}) => {
    const theme = useTheme();
    return (
        <div style={{background: theme.palette.primary.main, display: "flex", justifyContent: "space-between", alignItems: "center", color: "white", padding: 8}}>
            <Button onClick={onQuit} style={{color: "white"}}>Back</Button>
        </div>
    );
};

const ReviseScreen = ({words, goHome}) => {
    return (
        <div>
            <QuestionBar onQuit={goHome}/>
            <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: 16}}>
                <Paper style={{borderRadius: 16, width: "20cm", maxWidth: "90vw", padding: 16}}>
                    {words.map(word => {
                        const {folder, id, english} = word;
                        const file = folder ? `${folder}/${id}` : id;
                        return (
                            <div style={{display: "flex", alignItems: "center", borderBottom: "1px solid #ccc", padding: 8}}>
                                <p style={{fontSize: 20}}>{english}</p>
                                <AspectFitImage height={80} src={require("./arabic/" + file + ".png")} position="right center"/>
                            </div>
                        );
                    })}
                </Paper>
            </div>
        </div>
    );
};


export default ReviseScreen;