import {MuiThemeProvider} from "@material-ui/core";
import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "./App.css";
import MultiPlayerApp from "./Multiplayer";
import SinglePlayerApp from "./Singleplayer";
import theme from "./Theme";

export const QUIZ_STYLE_INPUT = "0";
export const QUIZ_STYLE_MULTI = "1";

const App = () => (
    <Router>
        <MuiThemeProvider theme={theme}>
            <Switch>
                <Route key="single" path="/" exact children={<SinglePlayerApp/>}/>
                <Route key="multi-setup" path="/multi" exact children={<MultiPlayerApp/>}/>
                <Route key="multi" path="/multi/:player" exact children={<MultiPlayerApp/>}/>
                <Route key="generator" path="/generator" exact children={<Generator/>}/>
                <Route children={<p>404</p>}/>
            </Switch>
        </MuiThemeProvider>
    </Router>
);

export default App;


const Generator = () => {

    const lesson = "c5hard";
    const description = "C5 hard words";
    const startAt = 1;

    /* const pronounsPast = ["He ", "They ", "You ", "I ", "You all ", "We ", "She "];
     const pronounsFuture = ["He ", "They ", "You ", "I ", "You all ", "We "];
     const actions = ["", "(You All) ", "Don't ", "(You All) Don't "];
     const bases1 = ["Repented"];
     const bases2 = ["repent"];
     const bases3 = ["Repent"];*/
    /*  let words = [
          ...bases1.flatMap(base => pronounsPast.map(p => p + base)),
          ...bases2.flatMap(base => pronounsFuture.map(p => p + base)),
          ...bases3.flatMap(base => actions.map(p => p + base + "!")),
          "the one who repents",
          "that which is repented",
          "to repent"
      ];*/

    const words = [
        "to overlook", "to wish", "to turn back", "to send forth", "face", "proof", "to judge", "to differ", "to prevent", "to destruct", "to be great", "to strive", "to humbly obey",
        "to originate", "to decree", "to speak", "to firmly believe", "religion", "to follow", "to benefit", "to intercede", "to reach", "to try", "to fulfill", "seclude for prayer", "to circumambulate",
        "to grant enjoyment", "to force", "foundation", "way of worship", "to become wise", "to be strong", "to purify", "to turn away from", "to become right", "to choose", "to come", "to enjoin", "to pass away",
        "to be upright", "to make distinction", "to suffice", "dissent", "to be sincere"
    ];

    const objects = words.map((word, index) => ({id: index + startAt, english: word, folder: lesson}));

    return JSON.stringify({name: lesson, description, words: objects});
};
