import React, {useEffect, useMemo, useState} from "react";
import Paper from "@material-ui/core/Paper";
import AspectFitImage from "./components/AspectFitImage";
import {AnswerInputMulti, AnswerInputText} from "./TestScreen";
import {QUIZ_STYLE_INPUT, QUIZ_STYLE_MULTI} from "./App";
import {useParams} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const SCMessageTypes = {
    NewQuestion: 1,
    ScoreBoard: 2
};

const CSMessageTypes = {
    Answer: 1
}

const GameState = {
    Question: 1,
    ScoreBoard: 2,
    Waiting: 3
}

const Scoreboard = ({scoreboard, player, lastAnswer, lastQuestion}) => {
    const {scores, questions} = scoreboard
    const wasRight = lastAnswer === lastQuestion?.english;
    const feedbackColor = wasRight ? "#279c27" : '#951717';
    const feedback = wasRight ? "Correct answer!" : "Incorrect! Answer: " + lastQuestion?.english;
    return (
        <div style={{textAlign: 'center'}}>
            <h1>Kaheet - Scoreboard</h1>
            <hr/>
            <p>You put: {lastAnswer}</p>
            <p style={{color: feedbackColor}}>{feedback}</p>
            <hr/>
            {scores.map((s, i) => (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center', fontSize: 24, fontWeight: s.player === player ? 600 : 500}}>
                        <p style={{width: 50}}>{i + 1}.</p>
                        <p style={{flex: 1}}>{s.player}</p>
                        <p style={{marginLeft: 16}}>{s.rightAnswers} / {questions}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

const MultiPlayerQuestionScreen = ({multiOptions, id, folder, onAnswered, quizStyle}) => {
    const [answer, setAnswer] = useState("");
    const [hasAnswered, setHasAnswered] = useState(false);

    const handleAnswered = () => {
        onAnswered(answer);
        setHasAnswered(true);
    }

    useEffect(() => {
        setAnswer("");
        setHasAnswered(false);
    }, [id]);

    const file = folder ? `${folder}/${id}` : id;

    return (
        <div>

            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 16}}>
                <Paper style={{borderRadius: 16, width: '20cm', maxWidth: '90vw', padding: 16}}>
                    <AspectFitImage src={require("./arabic/" + file + ".png")}/>
                    {hasAnswered && (
                        <div style={{textAlign: 'center'}}>
                            <p>Waiting for others....</p>
                        </div>
                    )}
                    {!hasAnswered && quizStyle === QUIZ_STYLE_INPUT && <AnswerInputText onAnswer={handleAnswered} answer={answer} setAnswer={setAnswer}/>}
                    {!hasAnswered && quizStyle === QUIZ_STYLE_MULTI && <AnswerInputMulti options={multiOptions} onAnswer={handleAnswered} answer={answer} setAnswer={setAnswer}/>}
                </Paper>
            </div>
        </div>
    )
}

const MultiplayerGame = ({player}) => {

    const [gameState, setGameState] = useState(GameState.Waiting)

    const [questionData, setQuestionData] = useState();
    const [scoreboard, setScoreboard] = useState();

    const [lastAnswer, setLastAnswer] = useState("");

    const connection = useMemo(() => {

        const connection = new WebSocket('ws://192.168.1.197:4444');

        connection.onopen = () => {
            console.log('Connected!');
        };

        // Log errors
        connection.onerror = error => {
            console.log('WebSocket Error ' + error);
        };

        // Log messages from the server
        connection.onmessage = e => {
            console.log('Server: ' + e.data);
            try {
                const decoded = JSON.parse(e.data);
                const {type, message} = decoded;
                if (type === SCMessageTypes.NewQuestion) {
                    onNewQuestion(message)
                }
                if (type === SCMessageTypes.ScoreBoard) {
                    onScoreBoard(message)
                }

            } catch (e) {
                console.error(e);
            }
        };

        return connection
    }, []);

    const onNewQuestion = question => {
        setQuestionData(question);
        setGameState(GameState.Question);
    }

    const onScoreBoard = sb => {
        setScoreboard(sb);
        setGameState(GameState.ScoreBoard);
    }

    const handleAnswer = answer => {
        setLastAnswer(answer);
        const message = JSON.stringify({type: CSMessageTypes.Answer, message: {answer, player}});
        const last = localStorage.lastMessage;
        if (last == message) return;
        localStorage.lastMessage = message;
        connection.send(message);
    }

    if (gameState === GameState.ScoreBoard) {
        return <Scoreboard scoreboard={scoreboard} player={player} lastAnswer={lastAnswer} lastQuestion={questionData?.question}/>
    }

    if (questionData == null) {
        return <Screen>
            <h1>Kaheet</h1>
            <p>Welcome, {player}! Waiting for others</p>
            <p>Tell them to hurry up...</p>
        </Screen>
    }

    const {question, options} = questionData;
    return <MultiPlayerQuestionScreen quizStyle={QUIZ_STYLE_MULTI} folder={question.folder} id={question.id} multiOptions={options} onAnswered={handleAnswer}/>
};


const Setup = () => {
    const [name, setName] = useState("");

    const go = () => {
        window.open("/multi/" + name, "_self");
    }

    return (
        <Screen>
            <h1>Kaheet</h1>
            <p>Please enter your name. No spaces or dashes or emoji allowed</p>
            <TextField variant="outlined" type="text" value={name} onChange={e => setName(e.target.value)} maxLength={10} style={{width: '100%'}}/>
            <br/> <br/>
            <Button variant="contained" color="primary" onClick={go} style={{width: '100%'}}>Enter Game</Button>
        </Screen>
    )
}

const Screen = ({children}) => (
    <div style={{padding: 16, textAlign: 'center'}}>
        {children}
    </div>
);

function MultiPlayerApp() {
    const {player} = useParams();

    const name = player?.trim()

    if (name == null || name.length === 0) {
        return <Setup/>
    }
    return <MultiplayerGame player={name}/>
}

export default MultiPlayerApp